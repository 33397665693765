import React from 'react'
import Image from 'next/image'

import Link from 'next/link'
import dynamic from 'next/dynamic';

const CommanBtn = dynamic(() => import('../Common/CommanBtn'));
const SocialMedia = dynamic(() => import('../Common/SocialMedia'));

function Billboard({billbord}:any) {
    return (
        <>
            <section className="billboard">
                {/* <div className="box_edge"></div> */}
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="caption" data-aos="fade" data-aos-delay="100">
                                <Image className="title_line" width={75} height={10}  src="/images/title-line.png" alt='title line' loading="lazy"/>
                                <span className='billbord_sub_title'>{billbord?.billboardSectionSubTitle}</span>
                                <h1 dangerouslySetInnerHTML={{ __html: billbord?.title || '' }} /> 
                                <div className="billbord_copy" dangerouslySetInnerHTML={{ __html: billbord?.description || '' }}></div>
                              
                                <div className="btn_holder home_btn">
                                    <CommanBtn href={billbord?.bannerButton?.url} target="_blank" className="" text={billbord?.bannerButton?.title} />
                                </div>
                                <div className="social_media">
                                    <SocialMedia/>
                                </div> 
                                <Link href="#mobile_next_section" rel="noreferrer nofollow" className="scroll_btn show_mobile section_scroll">
                                    <Image src='images/down_arrow.svg' width={36} height={66} alt='down arrow' loading="lazy"/>
                                </Link>
                            </div>
                        </div>
                        <div id='mobile_next_section' className="col-lg-7">
                            <div className="banner_right">
                                <div className="banner_img">
                                    <span className="banner_gd_circle"></span>
                                    <span className="banner_gd_circle lg"></span>
                                    <span className="banner_gd_circle sm"></span>
                                    <div className="fit_img">
                                        <Image src={billbord?.billboardImage?.sourceUrl} alt={billbord?.billboardImage?.altText} width={698} height={698}  loading='lazy' />
                                    </div>
                                    <Image className='banner_circle' src="/images/banner_circle.png" alt="banner circle" width={745} height={745} />
                                </div>
                                <Link href='request-your-free-website-audit' className="banner_text white" dangerouslySetInnerHTML={{ __html: billbord?.billboardCaptionTitle || '' }}>
                                   
                                </Link>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <Link href="#next_section" rel="noreferrer nofollow" className="scroll_btn home_darrow section_scroll">
                    <Image src='images/down_arrow.svg' width={36} height={66} alt='down arrow'  loading="lazy"/>
                </Link>

            </section>
            <div id="next_section"></div>
        </>
    )
}

export default Billboard